import request from "@/utils/request";
const controller = "/VocationalEducation/JobDaily";

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });
const getInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: { id }
  });

const insert = data =>
  request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
const update = data =>
  request({
    url: `${controller}/Update`,
    method: "post",
    data
  });
const deleteDaily = id =>
  request({
    url: `${controller}/Delete`,
    method: "post",
    params: { id }
  });
const comment = params =>
  request({
    url: `${controller}/Comment`,
    method: "post",
    params
  });
const read = params =>
  request({
    url: `${controller}/Read`,
    method: "get",
    params
  });
export { getPageList, getInfo, insert, update, deleteDaily, comment, read };
