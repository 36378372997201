<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
        <span>岗位：</span>
        <el-select class="mr-20" v-model="queryParams.JobId">
          <el-option
            v-for="item in jobList"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
        <span>日期：</span>
        <el-date-picker
          v-model="queryParams.Date"
          type="daterange"
          placeholder="选择日期"
          class="mr-20"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <el-button type="primary" @click="initialize">搜索</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      v-loading="loading"
      height="640px"
    >
      <template v-for="(item, index) in tableConfig">
        <el-table-column
          :key="index"
          :label="item.label"
          :prop="item.prop"
        ></el-table-column>
      </template>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scoped">
          <el-button
            type="text"
            icon="el-icon-view"
            class="btn-color"
            @click="handleView(scoped.row.Id)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getPageList } from "@/api/JobDaily";
import { queryApplyJobCatelog } from "@/api/jobApply";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    }
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      EnterpriseId: "",
      queryParams: {
        PageSize: 999,
        PageIndex: 1,
        JobId: ""
      },
      form: {
        Type: 1
      },
      tableData: [],
      tableConfig: [
        {
          label: "学生姓名",
          prop: "StudentName"
        },
        {
          label: "岗位名称",
          prop: "JobName"
        },
        {
          label: "日志类型",
          prop: "Type_V"
        },
        {
          label: "日志标题",
          prop: "Title"
        },
        {
          label: "日志内容",
          prop: "Content"
        }
      ],
      jobList: []
    };
  },
  async created() {
    if (this.RoleType === StaffTypeEnum.School) {
      let temp = this.employee.find(el => el.StaffType === this.RoleType);
      this.queryParams.SchoolId = temp.Employer.Id;
    } else if (this.RoleType === StaffTypeEnum.Enterprise) {
      let temp = this.employee.find(el => el.StaffType === this.RoleType);
      this.queryParams.EnterpriseId = temp.Employer.Id;
    } else {
      return null;
    }
    await this.getJobList();
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        await this.getTableData();
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async getTableData() {
      if (this.queryParams.Date) {
        let [startTime, endTime] = this.queryParams.Date;
        this.queryParams.StartTime = startTime;
        this.queryParams.EndTime = endTime;
      } else {
        this.queryParams.StartTime = "";
        this.queryParams.EndTime = "";
      }
      const res = await getPageList(this.queryParams);
      if (res.IsSuccess) {
        this.tableData = res.Result.Data;
      }
    },
    async getJobList() {
      const res = await queryApplyJobCatelog({
        schoolId: this.queryParams.SchoolId
      });
      if (res.IsSuccess) {
        this.jobList = res.Result;
        if (this.jobList.length > 0) {
          this.queryParams.JobId = this.jobList[0].Id;
        }
      }
    },
    handleView(id) {
      this.$router.push(`/school/practice/log/view?id=${id}`);
    },
    handleEdit(id) {
      this.$router.push(`/school/practice/log/edit?id=${id}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.table {
  overflow-x: auto;
}
</style>
